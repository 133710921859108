<template>
    <div>
        <Quote/>
        <GoBack next="Modul o'quvchilari"/>
        <div class="container-fluid">

            <form @submit.prevent="searchCourses"
                  id="Search"
                  class="row justify-content-center"
            >
                <div class="col-11 col-sm-10 col-md-6">
                    <label for="exampleInputId1" class="form-label">Modulni tanlang</label>
                    <b-form-select
                        v-model="search.course"
                        class="form-control mb-4 pl-4"
                        id="exampleInputId1"
                    >
                        <optgroup label="Aktiv modullar" class="text-primary">
                            <option
                                v-for="search of getTakeCourses"
                                :key="search.id"
                                :label="search.module.name + ' kursining ' + search.name + ' (' + search.startMonth.name + ')' + ' moduli'"
                                :value="search.id"
                                v-show="search.isActive === true"
                            ></option>
                        </optgroup>
                        <optgroup label="Arxiv modullar" class="text-danger">
                            <option
                                v-for="search of getTakeCourses"
                                :key="search.id"
                                :label="search.module.name + ' kursining ' + search.name + ' (' + search.startMonth.name + ')' + ' moduli'"
                                :value="search.id"
                                v-show="search.isActive === false"
                            ></option>
                        </optgroup>
                    </b-form-select>
                </div>
                <button
                    type="submit"
                    class="col-11 col-sm-10 col-md-2 btn btn-primary my-auto text-end text-center ml-md-2 ml-sm-0"
                >
                    Ko'rish
                </button>
            </form>
            <div v-if="getCourseStudents.length !== 0" class="d-flex justify-content-end me-5 mb-3">
                <vue-excel-xlsx
                    style="background: transparent; border: none"
                    :data="getCourseStudents"
                    :columns="columns"
                    :file-name="'Kurs o\'quvchilari'"
                    :file-type="'xlsx'"
                    :sheet-name="'O\'quvchilar'"
                >
                    <img
                        id="tooltip-target-1"
                        src="../assets/print-icon.png"
                        alt=""
                        style="cursor: pointer"
                    />
                </vue-excel-xlsx>
            </div>

            <div class="row justify-content-center d-md-none">
                <div class="col-12">
                    <table class="table table-hover border">
                        <thead>
                        <tr>
                            <th class="d-none d-lg-table-cell">
                                <input
                                    v-model="searchStudents.id"
                                    @keyup.enter="searchCourseStudents"
                                    type="text"
                                    placeholder="id"
                                    style="width: 110px;border-radius: 5px;"
                                />
                            </th>
                            <th>
                                <input
                                    v-model="searchStudents.givenName"
                                    @keyup.enter="searchCourseStudents"
                                    type="text"
                                    placeholder="Ismi"
                                    style="width: 110px;border-radius: 5px;"
                                />
                            </th>
                            <th>
                                <input
                                    v-model="searchStudents.email"
                                    @keyup.enter="searchCourseStudents"
                                    type="text"
                                    placeholder="Email"
                                    style="width: 110px;border-radius: 5px;"
                                />
                            </th>
                            <th class="d-none d-sm-table-cell">
                                <input
                                    v-model="searchStudents.phoneNumber"
                                    @keyup.enter="searchCourseStudents"
                                    type="text"
                                    placeholder="Telefon"
                                    style="width: 110px;border-radius: 5px;"
                                />
                            </th>
                            <b-th style="width: 100px">
                                <b-button
                                    v-b-tooltip.hover
                                    title="Izlash"
                                    class="border-0 p-0 size-"
                                    style="background-color: #E5E5E5; font-size:1.6vw;"
                                >
                                    <span>
                                      <img
                                          @click="searchCourseStudents"
                                          style=" height: auto; cursor: pointer; font-size:1.6vw;"
                                          src="../assets/search-icon.png"
                                          alt=""
                                      >
                                    </span>
                                </b-button>
                                <b-button
                                    v-b-tooltip.hover
                                    title="Tozalash"
                                    class="border-0 p-0 ms-1"
                                    style="background-color: #E5E5E5; font-size:1.7vw;"
                                >
                                    <span>
                                      <img
                                          @click="resetSaleSearchCourseStudentInfo"
                                          style=" height: auto; cursor: pointer; font-size:1.7vw;"
                                          src="../assets/eraser-icon.png"
                                          alt=""
                                      >
                                    </span>
                                </b-button>
                            </b-th>
                        </tr>
                        </thead>
                        <tbody
                        >
                        <tr
                            v-for="(course) in getCourseStudents"
                            :key="course.id"
                            class="tr"
                        >
                            <td class="td d-none d-lg-table-cell">{{ course.user.id }}</td>
                            <td class="td">{{ course.user.givenName }}</td>
                            <td class="td">{{ course.user.email }}</td>
                            <td class="td d-none d-sm-table-cell">{{ course.user.phoneNumber }}</td>
                            <td>
                                <router-link
                                    :to="'/edit-admin-user/' + course.user.id"

                                >
                                    <img src="../assets/pen.png" alt="">
                                </router-link>
                                <img
                                    @click="showModal(course.id)"
                                    style=" height: auto; cursor: pointer; font-size:1.7vw;"
                                    src="../assets/trash.png"
                                    alt="trash-icon"
                                    class="ms-2"
                                >
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="row justify-content-center d-none d-md-block">
                <div class="col-12">
                    <table class="table table-hover border">
                        <thead class="col-12">
                        <tr>
                            <th class="d-none d-md-table-cell pl-2">#</th>
                            <th class="d-none d-lg-table-cell">
                                <input
                                    v-model="searchStudents.id"
                                    @keyup.enter="searchCourseStudents"
                                    type="text"
                                    placeholder="Id"
                                    style="width: 150px;border-radius: 5px;height: 45%"
                                />
                            </th>
                            <th>
                                <input
                                    v-model="searchStudents.givenName"
                                    @keyup.enter="searchCourseStudents"
                                    type="text"
                                    placeholder="Ismi"
                                    style="width: 150px;border-radius: 5px;height: 45%"
                                />
                            </th>
                            <th class="d-none d-md-table-cell">
                                <input
                                    v-model="searchStudents.familyName"
                                    @keyup.enter="searchCourseStudents"
                                    type="text"
                                    placeholder="Familiyasi"
                                    style="width: 150px;border-radius: 5px;height: 45%"
                                />
                            </th>
                            <th class="d-none d-md-table-cell d-lg-table-cell">
                                <input
                                    v-model="searchStudents.phoneNumber"
                                    @keyup.enter="searchCourseStudents"
                                    type="text"
                                    placeholder="Telefon"
                                    style="width: 150px;border-radius: 5px;height: 45%"
                                />
                            </th>
                            <th class="d-none d-md-none d-xl-table-cell">
                                <input
                                    v-model="searchStudents.telegramNumber"
                                    @keyup.enter="searchCourseStudents"
                                    type="text"
                                    placeholder="Telegram"
                                    style="width: 150px;border-radius: 5px;height: 45%"
                                />
                            </th>
                            <th>
                                <input
                                    v-model="searchStudents.email"
                                    @keyup.enter="searchCourseStudents"
                                    type="text"
                                    placeholder="Email"
                                    style="width: 150px;border-radius: 5px;height: 45%"
                                />
                            </th>
                            <th class="d-md-none d-lg-table-cell">To`langan summa</th>
                            <th class="d-md-none d-xl-table-cell">Izoh qoldirish</th>
                            <b-th style="width: 100px">
                                <b-button
                                    v-b-tooltip.hover
                                    title="Izlash"
                                    class="border-0 p-0"
                                    style="background-color: #E5E5E5; font-size:1.6vw;"
                                >
                                    <span>
                                      <img
                                          @click="searchCourseStudents"
                                          style=" height: auto; cursor: pointer; font-size:1.6vw;"
                                          src="../assets/search-icon.png"
                                          alt=""
                                      >
                                    </span>
                                </b-button>
                                <b-button
                                    v-b-tooltip.hover
                                    title="Tozalash"
                                    class="border-0 p-0 ms-3"
                                    style="background-color: #E5E5E5; font-size:1.7vw;"
                                >
                                    <span>
                                      <img
                                          @click="resetSaleSearchCourseStudentInfo"
                                          style=" height: auto; cursor: pointer; font-size:1.7vw;"
                                          src="../assets/eraser-icon.png"
                                          alt=""
                                      >
                                    </span>
                                </b-button>
                            </b-th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                            v-for="(course, index) in getCourseStudents"
                            :key="course.id"
                            class="tr"
                        >
                            <td class="td1 d-md-table-cell">{{ index + 1 }}</td>
                            <td class="d-none d-lg-table-cell">{{ course.user.id }}</td>
                            <td class="td">{{ course.user.givenName }}</td>
                            <td class="td">{{ course.user.familyName }}</td>
                            <td class="td1 d-md-table-cell">{{ course.user.phoneNumber }}</td>
                            <td class="td1 d-md-none d-xl-table-cell">{{ course.user.telegramNumber }}</td>
                            <td class="td1">{{ course.user.email }}</td>
                            <td
                                v-if="course.course.boughtCourses.some(el => el.isPayed === true && el.user.id === course.user.id)"
                                data-label="Second name"
                                class="d-md-none d-lg-table-cell"
                            >
                                <span class="texts">{{ numberWithSpaces(course.course.price) }}</span>
                            </td>
                            <td
                                v-else
                                data-label="Second name"
                                class="d-md-none d-lg-table-cell"
                            >
                                <span class="texts">{{ 0 }}</span>
                            </td>
                            <td class="td1 d-md-none d-xl-table-cell pt-2">
                                <textarea
                                    class="p-1"
                                    style="border-radius: 5px;"
                                    v-model.lazy="course.user.studentInfo"
                                    @change="addStudentInfo(course.user)"
                                />
                            </td>
                            <td class="td1">
                                <router-link
                                    :to="'/edit-admin-user/' + course.user.id"
                                >
                                    <img src="../assets/pen.png" alt="">
                                </router-link>

                                <img
                                    @click="showModal(course.course.id, course.user.id)"
                                    style=" height: auto; cursor: pointer; font-size:1.7vw;"
                                    src="../assets/trash.png"
                                    alt="trash-icon"
                                    class="ms-3"
                                >
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div>
            <b-modal ref="modal-success" hide-footer title="Kadirov.Dev">
                <div class="d-block text-center">
                    <h3>Bu o'quvchini ushbu kursdan rostdan ham o'chirmoqchimisiz?</h3>
                </div>
                <b-button
                    class="mt-3" style="width: 100px;
                    background-color: green"
                    variant="btn btn-primary"
                    size="lg"
                    @click="hideErrorModal"
                >
                    Yo'q
                </b-button>
                <b-button
                    class="mt-3 float-end bg-danger"
                    style="width: 100px"
                    variant="btn btn-danger"
                    size="lg"
                    @click="deleteOneStudent"
                >
                    Ha
                </b-button>
            </b-modal>
            <b-modal ref="modal-success-add-student" hide-footer title="Kadirov.Dev">
                <div class="d-block text-center">
                    <h3>O'quvchi ma'lumoti muvaffaqiyatli qo'shildi.</h3>
                </div>
                <b-button
                    class="mt-3 float-end"
                    style="width: 100px"
                    variant="btn btn-primary"
                    size="md"
                    @click="hideSuccessAddStudent">
                    OK
                </b-button>
            </b-modal>
        </div>

        <b-overlay :show="show" no-wrap>
        </b-overlay>
    </div>
</template>

<script>
import GoBack from "../components/GoBack";
import Quote from "@/components/Quote";
import {mapActions, mapGetters} from "vuex";

export default {
    name: "CourseStudentPage",
    components: {
        Quote,
        GoBack,
    },
    data() {
        return {
            search: {
                course: 0,
            },
            searchStudents: {
                id: null,
                givenName: '',
                familyName: '',
                email: '',
                phoneNumber: '',
                telegramNumber: '',
                page: 1,
                course: ''
            },
            studentInfo: '',
            courseId: null,
            studentId: null,
            show: true,
            columns: [
                {
                    label: "Ismi",
                    field: "user.givenName"
                },
                {
                    label: "Familiyasi",
                    field: "user.familyName"
                },
                {
                    label: "Telefon raqami",
                    field: "user.phoneNumber"
                },
                {
                    label: "Telegram logini",
                    field: "user.telegramNumber"
                },
                {
                    label: "Email",
                    field: "user.email"
                },
                {
                    label: "To`lagan summasi",
                    field: "user.payedPrice",
                    dataFormat: this.numberWithSpaces
                },
                {
                    label: "Izoh",
                    field: "user.studentInfo"
                }
            ],
        }
    },
    methods: {
        ...mapActions([
            'fetchTakeCourse',
            'searchCourseStudentInfo',
            'fetchCourseStudents',
            'deleteStudent',
            'putStudentInfo',
            'deleteStudentFromCourse',
        ]),
        addStudentInfo(user) {
            let data = {
                id: user.id,
                studentInfo: user.studentInfo
            }

            this.putStudentInfo(data)
            this.$refs['modal-success-add-student'].show()
        },
        numberWithSpaces(price) {
            if (price === 0) {
                return 0;
            } else {
                return (price / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            }
        },
        userBoughtPrice(course) {
            if (course.user.boughtCourses && course.user.boughtCourses[0]) {
                for (let boughtCourse of course.user.boughtCourses) {
                    if (boughtCourse.isPayed) {
                        return boughtCourse.price
                    }
                }
            }

            return 0
        },
        searchCourses() {
            this.fetchCourseStudents(parseInt(this.search.course))
                .then(() => {
                    this.getCourseStudents.map(course => {
                        course.user.payedPrice = course.user.boughtCourses.length !== 0 ? this.userBoughtPrice(course) : 0
                    })
                    console.log(this.search.course)
                })
                .catch(() => {
                    console.log('search da xato')
                })
        },
        deleteOneStudent() {
            this.deleteStudentFromCourse({user: this.studentId, course: this.courseId})
                .then(() => {
                    this.$refs['modal-success'].hide()
                    this.show = true
                    this.fetchCourseStudents(parseInt(this.search.course))
                    this.show = false
                })
        },
        searchCourseStudents() {
            this.show = true
            if (this.search.course) {
                this.searchStudents.course = this.search.course
            }
            this.searchCourseStudentInfo(this.searchStudents)
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => {
                    this.show = false
                });
        },
        resetSaleSearchCourseStudentInfo() {
            this.searchStudents = {
                id: null,
                givenName: '',
                familyName: '',
                email: '',
                phoneNumber: '',
                telegramNumber: '',
                page: 1,
                course: 0
            }
            this.searchCourses()
        },
        showModal(courseId, studentId) {
            this.courseId = courseId
            this.studentId = studentId
            this.$refs['modal-success'].show()
        },
        hideErrorModal() {
            this.$refs['modal-success'].hide()
        },
        hideSuccessAddStudent() {
            this.$refs['modal-success-add-student'].hide()
        }
    },
    computed: {
        ...mapGetters(['getCourseStudents', 'getTakeCourses'])
    },
    mounted() {
        this.show = true
        this.searchCourses()
        this.fetchTakeCourse()
            .then(() => {
                if (this.$route.params.courseId) {
                    this.search.course = this.$route.params.courseId
                    this.fetchCourseStudents(this.search.course)
                }
                this.show = false
            })
    },
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Comfortaa&family=Open+Sans:wght@400;600&display=swap');

* {
    margin: 0;
    padding: 0;
}

img {
    width: 20px;
    height: 20px;
}

input {
    padding: 5px;
    border: 1px solid black;
}

input:focus {
    border: none;
    outline: 2px solid #b505b4;
}

input::placeholder {
    padding: 0.5rem;
    font-size: 12px;
}

#Search {
    margin-top: 111px;
    margin-bottom: 50px;
}

select {
    height: 38px;
    border: 1px solid #80007F;
    border-radius: 10px;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("../../public/img/select.svg") !important;
    background-repeat: no-repeat;
    background-size: 25px !important;
}

select::-ms-expand {
    display: none !important;
}

button {
    height: 38px;
    border-radius: 10px;
    background: #80007F;
}

p {
    font-family: 'Comfortaa', cursive;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    color: #EAEAEA;
}

.form-control:focus {
    box-shadow: 0 0.5px 0.5px #FFFFFF inset, 0 0 5px #b505b4 !important;
}

.form-control:hover {
    box-shadow: 0 0.5px 0.5px #FFFFFF inset, 0 0 5px #b505b4 !important;
}

table {
    max-width: 100%;
    min-width: 100%;
}

table th {
    height: 60px;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    text-align: center;
    background-color: #E5E5E5;
    color: rgba(0, 0, 0, 0.5);
}

.td {
    text-align: start;
    padding-left: 3em;
}

tbody td {
    height: 60px;
    width: 100px;
}

table tbody tr td {
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 1px;
    text-align: center;
    padding-left: 0;
    width: 185px;
    height: 56px;
    top: 56px;
    background-color: #FFFFFF;
    color: #000000;
}

#tooltip-target-1 {
    width: 100%;
    height: 100%;
}

</style>
